/* exo-regular - latin */
@font-face {
  font-family: "Exo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./exo-v9-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Exo Regular"), local("Exo-Regular"),
    url("./exo-v9-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./exo-v9-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./exo-v9-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./exo-v9-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./exo-v9-latin-regular.svg#Exo")
      format("svg"); /* Legacy iOS */
}
/* exo-600 - latin */
@font-face {
  font-family: "Exo";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./exo-v9-latin-600.eot"); /* IE9 Compat Modes */
  src: local("Exo SemiBold"), local("Exo-SemiBold"),
    url("./exo-v9-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./exo-v9-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./exo-v9-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("./exo-v9-latin-600.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./exo-v9-latin-600.svg#Exo") format("svg"); /* Legacy iOS */
}
